import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CotizacionesProvider } from './hooks/useCotizaciones';
import Layout from './components/Layout';

const Home = lazy(() => import('./components/Home'));
const Cotizaciones = lazy(() => import('./pages/Cotizaciones'));
const Tenencias = lazy(() => import('./pages/Tenencias'));

function App() {
  return (
    <CotizacionesProvider>
      <Router>
        <Layout>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/cotizaciones" element={<Cotizaciones />} />
              <Route path="/tenencias" element={<Tenencias />} />
            </Routes>
          </Suspense>
        </Layout>
      </Router>
    </CotizacionesProvider>
  );
}

export default App;


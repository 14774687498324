export const API_URL = process.env.REACT_APP_API_URL;
export const DOLAR_API_URL = process.env.REACT_APP_DOLAR_API_URL;
export const CURRENT_DOLAR_API_URL = process.env.REACT_APP_CURRENT_DOLAR_API_URL;
export const COTIZACIONES_STORAGE_KEY = 'cotizaciones_cache';
export const CACHE_DURATION = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
export const TRANSACTIONS_KEY = 'transactions';
export const DOLAR_STORAGE_KEY = 'dolar_cotizaciones';
export const CURRENT_DOLAR_CACHE_KEY = 'current_dolar_cotizacion';
export const CACHE_EXPIRATION = 5 * 60 * 1000; // 5 minutes in milliseconds


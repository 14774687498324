import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { fetchCotizaciones } from '../services/api';

const CotizacionesContext = createContext();

export const useCotizaciones = () => {
  const context = useContext(CotizacionesContext);
  if (!context) {
    throw new Error('useCotizaciones must be used within a CotizacionesProvider');
  }
  return context;
};

export const CotizacionesProvider = ({ children }) => {
  const [cotizaciones, setCotizaciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadCotizaciones = useCallback(async () => {
    try {
      setLoading(true);
      const data = await fetchCotizaciones();
      setCotizaciones(data);
      setError(null);
    } catch (err) {
      console.error('Error fetching cotizaciones:', err);
      setError('Error al cargar las cotizaciones. Por favor, intente nuevamente más tarde.');
    } finally {
      setLoading(false);
    }
  }, []);

  const refreshCotizaciones = useCallback(() => {
    loadCotizaciones();
  }, [loadCotizaciones]);

  useEffect(() => {
    loadCotizaciones();

    const intervalId = setInterval(refreshCotizaciones, 5 * 60 * 1000);

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        refreshCotizaciones();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [loadCotizaciones, refreshCotizaciones]);

  return (
    <CotizacionesContext.Provider value={{ cotizaciones, loading, error, refreshCotizaciones }}>
      {children}
    </CotizacionesContext.Provider>
  );
};


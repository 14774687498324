import axios from 'axios';
import { API_URL, COTIZACIONES_STORAGE_KEY, CACHE_DURATION } from '../config/constantes'

const api = axios.create({
  baseURL: API_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: true  // Importante para CORS
});

export const fetchCotizaciones = async () => {
  try {
    const storedData = localStorage.getItem(COTIZACIONES_STORAGE_KEY);
    if (storedData) {
      const { data, timestamp } = JSON.parse(storedData);
      if (new Date().getTime() - timestamp < CACHE_DURATION) {
        return data;
      }
    }

    const { data } = await api.get('/api/v1/cotizaciones/');
    localStorage.setItem(COTIZACIONES_STORAGE_KEY, JSON.stringify({ data, timestamp: new Date().getTime() }));
    return data;
  } catch (error) {
    console.error('Error fetching cotizaciones:', error);
    throw error;
  }
};

export const saveTransaction = async (type, data) => {
  try {
    const { data: responseData } = await api.post(`/transactions/${type}/`, data);
    return responseData;
  } catch (error) {
    console.error(`Error saving ${type} transaction:`, error);
    throw error;
  }
};

export const getTransactions = async (type) => {
  try {
    const { data } = await api.get(`/transactions/${type}/`);
    return data;
  } catch (error) {
    console.error(`Error fetching ${type} transactions:`, error);
    throw error;
  }
};

export const deleteTransaction = async (type, id) => {
  try {
    await api.delete(`/transactions/${type}/${id}/`);
  } catch (error) {
    console.error(`Error deleting ${type} transaction:`, error);
    throw error;
  }
};

export default api;


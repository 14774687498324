import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Twitter, Instagram } from 'lucide-react';

const Layout = ({ children }) => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const socialLinks = [
    { icon: Twitter, url: process.env.REACT_APP_TWITTER, label: 'Twitter' },
    { icon: Instagram, url: process.env.REACT_APP_INSTAGRAM, label: 'Instagram' },
  ];

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/" className="text-xl font-bold text-gray-800">
                  MFinanzas
                </Link>
              </div>
              <div className="ml-6 flex space-x-8">
                {['cotizaciones', 'tenencias'].map((route) => (
                  <Link
                    key={route}
                    to={`/${route}`}
                    className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium transition-colors duration-200 ease-in-out
                      ${isActive(`/${route}`)
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                      }`}
                  >
                    {route.charAt(0).toUpperCase() + route.slice(1)}
                  </Link>
                ))}
              </div>
            </div>
            <div className="flex items-center space-x-4">
              {socialLinks.map(({ icon: Icon, url, label }) => (
                <a
                  key={label}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
                  aria-label={label}
                >
                  <Icon size={20} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {children}
      </main>
    </div>
  );
};

export default Layout;

